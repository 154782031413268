import { AadeSyncStatus } from '@biorec/admin/domain';

export function isNullOrUndefined(value: any) {
	return value === null || value === undefined;
}

export function resolveAadeStatusIcon(item: {
	aadeStatus?: AadeSyncStatus;
	[key: string]: any;
}) {
	switch (item.aadeStatus) {
		case AadeSyncStatus.Success:
			return 'mdi mdi-check-circle text-green-500 mdi-24px';
		case AadeSyncStatus.Error:
			return 'mdi mdi-close-circle text-red-500 mdi-24px';
		case AadeSyncStatus.Sync:
			return 'mdi mdi-sync-circle text-yellow-500 mdi-24px'
	}
	return 'minus';
}

export function resolveAadeStatusTooltip(item: {
	aadeResponse?: any;
	aadeStatus?: AadeSyncStatus;
	[key: string]: any;
}) {
	const aadeResponse = item.aadeResponse || {};
	switch (item.aadeStatus) {
		case AadeSyncStatus.Error:
			if (aadeResponse?.errors?.error) {
				return (aadeResponse.errors.error as [])
					.map((err: { [key: string]: any }) =>
						err.message
							? `<p style="overflow-wrap: anywhere;">${err.message}</p>`
							: ''
					)
					.filter((m) => m)
					.join('<br />');
			}
			break;
		case AadeSyncStatus.Success:
			return `<span style="overflow-wrap: anywhere;">
				invoiceUid: ${aadeResponse.invoiceUid || ''}<br />
				statusCode: ${aadeResponse.statusCode || ''}<br />
				invoiceMark: ${aadeResponse.invoiceMark || ''}</span>`;
		case AadeSyncStatus.Sync:
			return $localize`Record is pending synchronization`;
	}
	return '';
	// TODO; add way the span to take method onClick,
}

export function resolveIconByCondition(condition: boolean) {
	/*
		If condiditon == true { green check icon },
		If condiditon != true { red cross icon }
		Can be used in many cases if needed
	*/
	if (condition) {
		return 'mdi mdi-check p-badge p-badge-success flex border-circle w-1rem justify-content-center h-1.4rem';
	} else {
		return 'mdi mdi-close p-badge p-badge-danger flex border-circle w-1rem justify-content-center h-1.4rem';
	}
}
